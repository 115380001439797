<template>
  <div class="pages">
    <div class="page">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :highlight-current="true"
          accordion
          @node-click="handleNodeClick"
        />
      </div>

      <div class="task_box">
        <div class="out">
          <div class="search">
            <div>
              <el-input v-model="search" placeholder="请输入内容">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
          </div>
          <div class="btns" @click="exportlist">
            <div class="el-icon el-icon-upload2"></div>
            <div>导&nbsp;出</div>
          </div>
        </div>
        <!-- select 筛选 -->
        <div class="select_box"></div>
        <div class="oper_box">
          <div class="s_item">
            <div class="s_lable">搜索：</div>
            <div class="s_input">
              <el-input
                v-model="keyword"
                placeholder="搜索组织/设备ID/设备名称"
              />
            </div>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">开始时间：</div>
            <el-date-picker
              v-model="date_screen"
              type="datetime"
              placeholder="选择日期"
              @change="timeChange(date_screen)"
            />
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">结束时间：</div>
            <el-date-picker
              v-model="end_time"
              type="datetime"
              placeholder="选择日期"
              @change="timeChangeE(end_time)"
            />
          </div>
          <div class="btns" @click="submit">查询</div>
        </div>
        <div class="table">
          <table>
            <tr>
              <th>时间</th>
              <th>接头温度</th>

              <th>环境温度</th>
              <th>局放峰值</th>
              <th>局放均值</th>
              <th>局放频率次数</th>

              <th>水浸</th>
              <th>电池电压</th>
              <th>信号强度</th>
            </tr>
            <!-- <tr v-for="(item, index) in datelist">
              <td>{{item.name}}</td>
              <th v-for="ite in item.value">{{ite}}</th>
            </tr> -->
            <tr v-for="(item, index) in datelist" :key="index">
              <td>{{ item.date }}</td>
              <td>{{ item.temperature }}</td>

              <td>{{ item.ambient_temperature }}</td>
              <td>{{ item.pv }}</td>
              <td>{{ item.mv }}</td>
              <td>{{ item.df }}</td>

              <td>{{ item.humidity }}</td>
              <!-- <td>{{item.vbat}}</td>
                    <td>{{item.rssi}}</td> -->
              <td>3.65</td>
              <td>28</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      numoptions: [
        {
          value: 'days',
          label: '日',
        },
        {
          value: 'month',
          label: '月',
        },
        {
          value: 'year',
          label: '年',
        },
      ],
      selectValue: '',
      // 副 菜单
      radio: 1,
      // 树状图选择
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title',
      },
      // 参数

      device_id: '',
      date_screen: '',
      keyword: '',
      time: '',
      search: '',
      //
      datelist: [],
      date: [],
      end_time: '',
    }
  },
  mounted: function () {
    this.getzuzhiList()
  },
  methods: {
    // 导出
    exportlist() {
      if (this.device_id == '') {
        this.$message.error('请选择组织')
        return
      }
      if (this.screen == '') {
        this.$message.error('请选择时间')
        return
      }
      if (this.cycle == '') {
        this.$message.error('请选择周期')
        return
      }
      window.location.href =
        'https://api.cdksense.com/api/Exports/cable_report?device_id=' +
        this.device_id +
        '&date_screen=' +
        this.time
    },
    async submit() {
      const res = await this.$http.post('device/report', {
        device_id: this.device_id,
        search: this.search,
        date_screen: this.time,
        end_time: this.end_time,
        keyword: this.keyword,
      })
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      // this.date = res.data.date
      // this.datelist = res.data.list
      this.date = res.data
      this.datelist = res.data
    },
    // 周期下拉
    changeTime(e) {
      this.cycle = e
    },
    timeChangeE(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      var time = y + '-' + m + '-' + d + ' '
      this.end_time = time
    },
    // 时间选择
    timeChange(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      var time = y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
      this.time = time
    },

    // 组织 获取
    async getzuzhiList() {
      const res = await this.$http.post('Organize/left')
      this.treeData = res.data
    },

    // 左侧二级菜单
    handleNodeClick(data) {
      console.log(data, '左侧二级菜单')
      if (data.id) {
        this.device_id = data.id
        this.submit()
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
}
</script>
<style lang="scss" scoped>
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  background: rgba(35, 59, 103, 0.67);
  border-radius: 6px;
  border: 1px solid #4486ff;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background: rgba(35, 59, 103, 0.67);
  color: #ffffff;
  // overflow-x: auto;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-date-editor {
  width: auto !important;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node > .el-tree-node__children {
  overflow: visible;
}
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #1b284d;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}
.out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btns {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: 25px;
    background: #233b67;
    border-radius: 5px;
    border: 1px solid #4486ff;
    width: 70px;

    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    cursor: pointer;
    .el-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #4486ff !important;
  }
  // charts
  .onecharts {
    width: 100%;
    height: 300px;
  }
  .twocharts {
    width: 100%;
    height: 300px;
  }
  .chartsTitle {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }

  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #4486ff !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }

  .oper_box {
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      width: 100px;
      height: 40px;
      background: #233b67;
      border-radius: 6px;
      border: 1px solid #4486ff;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      // margin-left: 80px;
      margin-right: 80px;
      flex: 1;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      width: 100px;
      height: 50px;
      background: #233b67;
      border-radius: 6px;
      border: 1px solid #4486ff;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
.table {
  margin-top: 30px;
  height: 750px;
  overflow-y: auto;
  table,
  th,
  td {
    border: 1px solid #4486ff;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e5e5e5;
    border-collapse: collapse;
  }
  table {
    width: 100%;

    border: 2px solid #4486ff;
  }
  th {
    height: 50px;
    width: 96px;
  }
  td {
    text-align: center;
    width: 180px;
    height: 50px;
  }
}
</style>
